<template>
  <b-row>
    <b-col cols="4">
      <b-card title="部门列表">
        <vue-tree-list
          v-if="org"
          key="vue-tree-list-root"
          :model="org"
          default-tree-node-name="新部门"
          default-leaf-node-name="新成员"
          :default-expanded="true"
          @change-name="onChangeName"
          @delete-node="onDel"
          @add-node="onAddNode"
          @click="onClick"
        />
      </b-card>
    </b-col>

    <b-col cols="8">
      <b-card
        ><b-card-header>
          <b-card-title>部门成员</b-card-title>
          <b-card-title>
            <b-button
              variant="primary"
              @click="showAddUser = true"
              :disabled="currentOrg == null"
            >
              <feather-icon icon="UserPlusIcon" />
              <span class="align-middle"> 增加成员</span></b-button
            >
          </b-card-title>
        </b-card-header>
        <b-table striped hover responsive :items="users" :fields="userFields">
          <template #cell(avatar)="data">
            <b-avatar :src="data.value" />
          </template>
          <template #cell(roleId)="data">
            <span>{{ roleName(data.item.roleId) }}</span>
          </template>
          <template #cell(eid)="data">
            <span>{{ eidName(data.item.eid) }}</span>
          </template>
          <template #cell(action)="data">
            <b-button
              variant="flat-danger"
              @click="onDeleteUser(data.item)"
              :disabled="data.item.default == 1"
            >
              <feather-icon icon="UserMinusIcon" class="mr-50" />
              <span class="align-middle">移除</span></b-button
            >
          </template>
        </b-table>
      </b-card>

      <b-modal
        centered
        title="选择成员"
        size="lg"
        v-model="showAddUser"
        @ok="doSearch"
        ok-only
        ok-title="关闭"
      >
        <b-table
          striped
          hover
          responsive
          :items="toSelectUsers"
          :fields="userFields"
        >
          <template #cell(avatar)="data">
            <b-avatar :src="data.value" />
          </template>
          <template #cell(roleId)="data">
            <span>{{ roleName(data.item.roleId) }}</span>
          </template>
          <template #cell(eid)="data">
            <span>{{ eidName(data.item.eid) }}</span>
          </template>
          <template #cell(action)="data">
            <b-button
              variant="flat-danger"
              @click="onAddUser(data.item)"
              :disabled="data.item.default == 1"
            >
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle">添加</span></b-button
            >
          </template>
        </b-table>
      </b-modal>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BRow,
  BCol,
  BTable,
  BAvatar,
  BButton,
  BModal,
} from "bootstrap-vue";

import { VueTreeList, Tree, TreeNode } from "vue-tree-list";

function newNode(id, pId, name) {
  return {
    id: id,
    pId: pId,
    name: name,
    dragDisabled: true,
    addTreeNodeDisabled: false,
    addLeafNodeDisabled: true,
    editNodeDisabled: false,
    delNodeDisabled: false,
    children: [],
  };
}

function nodeToRecord(node, isNew) {
  return {
    id: isNew ? 0 : node.id,
    eid: 0,
    pId: node.pid,
    name: node.name,
  };
}

export default {
  name: "Organization",

  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
    BTable,
    BAvatar,
    BButton,
    BModal,
    VueTreeList,
    // VueContext,
    // Vue2OrgTree,
  },

  data() {
    return {
      //tree view
      org: null,

      // current selected org
      currentOrg: null,

      // all users
      allUsers: [],

      // user list of current org
      users: [],

      // user fields
      userFields: [
        {
          key: "avatar",
          label: "头像",
        },
        {
          key: "nickName",
          label: "昵称",
        },
        {
          key: "email",
          label: "邮箱",
        },
        {
          key: "action",
          label: "操作",
          tdClass: "text-center",
          thClass: "text-left",
        },
      ],

      // add user modal
      showAddUser: false,
      selectedUser: null,
    };
  },

  computed: {
    toSelectUsers() {
      return this.allUsers.filter((user) => user.orgId == 0);
    },
  },

  created() {
    //load all org
    this.doSearch();
  },

  methods: {
    doSearch() {
      this.$http
        .get("/organizations", { p: 0, ps: 9999 })
        .then((res) => {
          // debugger
          let data = res.data.data.list;
          // data.sort((a, b) => a.parent > b.parent);
          this.parseData(data);
        })
        .catch(function (err) {
          console.log(err);
        });
    },

    /**
     * convert rows to a tree
     * @param rows rows of data
     */
    parseData(data) {
      // id -> path mapp
      let aryTmp = {};

      // tree object
      let org = [];

      // map all org id->obj
      data.forEach((item) => {
        let isRoot = item.root ? true : false;
        let oitem = {
          id: item.id,
          pId: item.pId,
          name: item.name,
          dragDisabled: true,
          addTreeNodeDisabled: false,
          addLeafNodeDisabled: true,
          editNodeDisabled: isRoot ? true : false,
          delNodeDisabled: isRoot ? true : false,
          children: [],
        };
        aryTmp[item.id.toString()] = oitem;
        //me.$set(me.aryTmp, item.id.toString(), oitem);
        if (item.id > this.maxID) this.maxID = item.id;
      });

      //convert it to a tree
      data.forEach((item) => {
        let oi = aryTmp[item.id.toString()];
        if (item.pId) {
          let op = aryTmp[item.pId.toString()];
          op.children.push(oi);
        } else {
          org.push(oi);
        }
      });

      // console.log(org);
      this.org = new Tree(org);
    },

    /**
     * @param node {'id', 'oldName', 'newName'}
     */
    onChangeName(node) {
      if (!this.oldName) this.oldName = node.oldName;
      // console.log(node.oldName)
      if (node.eventType == "blur") {
        // console.log(node);
        if (this.oldName != node.newName) {
          //console.log(this.oldName, node.newName);
          // debugger
          this.$http
            .put("/organizations/" + node.id, { name: node.newName })
            .then((res) => {
              // console.log(res);
              this.oldName = null;
            })
            .catch((err) => {
              console.log(err);
            });

          this.oldName = null;
        }
      }
    },

    /**
     * @param treeNode TreeNode {
     *   id,
     *   isLeaf,
     *   dragDisabled,
     *   addTreeNodeDisabled,
     *   addLeafNodeDisabled,
     *   editNodeDisabled,
     *   delNodeDisabled,
     *   children
     *   }
     */
    onDel(treeNode) {
      this.$swal({
        title: "删除确认",
        text: "是否确认删除该部门？",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "确认",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$http
            .delete("/organizations/" + treeNode.id)
            .then((res) => {
              treeNode.remove();
              this.doSearch();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },

    /**
     * @param treeNode TreeNode {
     *   id,
     *   isLeaf,
     *   dragDisabled,
     *   addTreeNodeDisabled,
     *   addLeafNodeDisabled,
     *   editNodeDisabled,
     *   delNodeDisabled,
     *   children
     *   }
     */
    onAddNode(treeNode) {
      console.log("new node " + treeNode);
      Object.assign(treeNode, {
        dragDisabled: true,
        addTreeNodeDisabled: false,
        addLeafNodeDisabled: true,
        editNodeDisabled: false,
        delNodeDisabled: false,
      });

      // debugger
      let record = nodeToRecord(treeNode, true);
      this.$http
        .post('/organizations', record)
        .then(() => {
          this.doSearch()
          // debugger;
          // let data = res.data.data;
          // let newNode = newNode(data.id, data.pId, data.name);
          // treeNode.addChild(newNode);
        }).catch(err => {
          console.log(err)
        })
    },

    // @param TreeNode
    onClick(params) {
      this.currentOrg = params
      const { id } = params
      // console.log("click " + id);
      this.$http
        .get('/users', { params: { eid: 1, orgId: id } })
        .then(res => {
          this.allUsers = res.data.data.list
          this.users = res.data.data.list.filter(item => item.orgId === id)
        })
    },

    onDeleteUser(user) {
      // console.log("delete user", user);
      this.$swal({
        title: '移除确认',
        text: '是否确认移除该用户？',
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: '确认',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // eslint-disable-next-line no-param-reassign
          user.orgId = 0
          this.$http
            .put(`/users/${  user.id}`, user)
            .then((res) => {
              this.doSearch();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },

    onAddUser(user) {
      let orgId = this.currentOrg.id;
      user.orgId = orgId;
      this.$http
        .put("/users/" + user.id, user)
        .then((res) => {
          // this.doSearch();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

